$navy: #000462;
$diamond-blue: #03BFE9;
$orange: #FF9900;

body {
  font-family: "Roboto", sans-serif;
  color: rgb(10%, 10%, 10%);
  background-color: #fff;
  font-size: 16px;
  margin: 0;
}

html {
  background-color: rgba(3, 191, 233, 0.06);
}

.bodyBackground {
  background-color: rgba(3, 191, 233, 0.06);
  display: flex;
  justify-content: center;
  min-width: 100%;
  flex-direction: column;
}

#search {
  display: flex;
}

.mainComponents {
  display: flex;
  align-self: center;
  width: 70%;
  flex-direction: column;
}

.searchComponent {
  display: flex;
  align-self: center;
  width: 70%;
  flex-direction: column;
  margin: auto;
}

.messageView {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: stretch;
  align-content: center;
  background: #fff;
  div {
    flex: 2;
  }
  .names {
    border-style: solid;
    border-width: 0 1px 0 0;
    border-color: $diamond-blue;
  }
  ul {
    flex: 1;
    li {
      border-style: solid;
      border-width: 0 0 1px 0;
      border-color: $diamond-blue;
    }
  }
}

ul {
  list-style: none;
  padding: 0;
  margin-top: 0;
  li {
    display: flex;
    background-color: #fff;
    padding: 0px;
    padding-bottom: 5px;
    margin: 10px;
    flex-direction: row;
    .avatar {
      background-color: $diamond-blue;
      margin: 10px;
    }
    p {
      margin: 10px;
    }
  }
  .feedItem {
    padding: 10px 10px 15px 10px;
  }
  .searchItem {
    border-style: solid;
    border-width: 0 0 1px 0;
    border-color: $diamond-blue;
    margin: 0 10px 0 10px;
    padding: 10px 5px 10px 5px;

  }
}

.messages {
  width: 100%;
  list-style: none;
  padding: 0;
  margin-top: 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  border-width: 0;
  .myMessage {
    width: 40%;
    background-color: $diamond-blue;
    align-self: flex-end;
    border-radius: 10px;
    border-width: 0;
    padding: 5px;
  }
  .theirMessage {
    width: 40%;
    background-color: $orange;
    align-self: flex-start;
    border-radius: 10px;
    border-width: 0;
    padding: 5px;
  }
}

.feed {
  display: flex;
  flex-direction: column-reverse;
}

.search {
  display: flex;
  flex-direction: column;
}


 h1 {
   font-size: 20px;
   font-weight: normal;
   color: $diamond-blue;
   padding-left: 20px;
   margin-bottom: 0px;
 }

.descriptor {
  color: $diamond-blue;
}

.bio {
  background-color: #fff;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
}

.root {
   flexGrow: 1;
}

.leftNav {
  display: inline-flex;
  flex: 1;
  a {
    padding: 0;
  }
}

.rightNav {
  .navatar {
    width: 39px;
    height: 39px;
  }
}
.row {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin: 10px;
  .activityImg {
    flex: 1;
    min-width: 1%;
    min-height: 1%;
    width: 20%;
    padding: 10px;
  }
}

#bigavatar {
    width: 100px;
    height: 100px;
    margin-right: 20px;
}
#searchBarInput {
  width: 500px;
  color: #fff;
  border-bottom-color: #fff;
  &:before {
    background-color: #fff;
  }
  &:after {
    background-color: #fff;
  }
}

a {
  color: $orange;
  text-decoration: none;
}

.button {
  span  {
    color: #fff;
    padding: 0;
  }
}

.bio .bluebutton {
  align-self:center;
}
.centered {
  display: flex;
  justify-content: center;
  .bluebutton {
    background-color: $diamond-blue;
    color: #fff;
    align-self: center;
    display: inline;
  }
}

#bluebutton {
  background-color: $diamond-blue;
  color: #fff;
  align-self: center;
  display: inline;
}

.header {
    background-image: url('./img/harborbackground.jpg');
    background-size: cover;
    height: 100vh; // mess with the height later to be better
    color: $navy;
}

.overlay {
  background-color: rgba(255, 255, 255, 0.6);
  padding: 15px;
  border-radius: 8px;
}
.plain {
  background: #fff;
  color: $navy;
}

.signInComponent {
  display: flex;
  align-self: center;
  width: 40%;
  flex-direction: column;
  padding: 20px;
  margin: auto;
}
